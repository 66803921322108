<template>
  <form @submit.prevent="changeEmail">
    <modal-card title="Change your email address" :processing="isProcessing">
      <b-field label="Email">
        <b-input
          ref="email"
          v-model="form.email"
          type="email"
          placeholder="Enter your new email address"
          :disabled="isProcessing"
        />
      </b-field>
      <button
        slot="footer"
        :class="{ 'is-loading': isProcessing }"
        :disabled="formIsDisabled"
        type="submit"
        class="button is-success"
      >
        Change email
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "ChangeEmailModal",
  props: {
    email: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      isProcessing: false,
      form: {
        email: ""
      }
    };
  },
  computed: {
    formIsValid() {
      if (this.$_.isEmpty(this.form.email)) return false;
      if (this.form.email === this.email) return false;
      if (!this.$v.isEmail(this.form.email)) return false;
      return true;
    },
    formIsDisabled() {
      return !this.formIsValid || this.isProcessing;
    }
  },
  mounted() {
    this.$nextTick(() => this.focusOnEmail());
  },
  methods: {
    focusOnEmail() {
      return (
        this.$refs.email && this.$refs.email.$el.querySelector("input").focus()
      );
    },
    changeEmail() {
      this.isProcessing = true;
      this.$store
        .dispatch("auth/changeEmail", {
          email: this.form.email
        })
        .then(result => {
          this.$emit("changed");
          if (result && result.message) {
            this.$toast.open({
              message: `${result.message}`,
              type: "is-success"
            });
          }
        })
        .catch(error => {
          this.isProcessing = false;
          this.focusOnEmail();
          if (error && error.message) {
            this.$toast.open({
              message: `${error.message}`,
              type: "is-danger"
            });
          }
        });
    }
  }
};
</script>
